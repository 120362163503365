import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalView: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 405,
    height: 570,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    padding: 32,
  },

  items: {
    flex: 1,
    width: "100%",
    overflowY: "auto",
  },

  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  name: {
    width: 160,
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 18,
    color: "#000",
  },

  price: {
    width: 52,
    fontSize: 12,
    fontWeight: 600,
    color: "#000",
  },

  amount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    "& span": {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 10,
      marginRight: 10,
      color: "#000",
    },
  },

  icon: {
    "& svg": {
      height: 16,
      color: "#7F7F88",
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 105,

    "& span": {
      fontSize: 18,
      fontWeight: "bold",
    },
  },

  total: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  actions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  divider: {
    width: 10,
  },

  observation: {
    display: "flex",
    width: 160,
    marginLeft: 18,
    "& span": {
      fontSize: 10,
    },
  },

  details: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
