import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { useStore } from "../../../store";
import useStyles from "./styles";
import {
  useCreatePaymentOrder,
  useCreatePaymentVisitor,
  useGetTransaction,
} from "../../../api/payment/hooks";
import {
  CreatePaymentOrderResponse,
  PaymentMethod,
  CreatePaymentOrder,
} from "../../../types";
import { getPaymentMethodName } from "../../../utils/getPaymentMethodName";
import { LoadingModal } from "../../../components";
import { getTerminalId } from "../../../services";

export const TotemTerminalPayment: React.FC = () => {
  const Styles = useStyles();
  const { userManager, navigation, order } = useStore();
  const {
    state: { paymentMethod },
  }: {
    state: {
      paymentMethod?: PaymentMethod;
    };
  } = useLocation();
  const [transactionId, setTransactionId] = useState<string>("0");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const { data: transaction, refetch: refetchTransaction } = useGetTransaction(
    transactionId,
    !!transactionId
  );
  const { isLoading } = useCreatePaymentVisitor();
  const { mutateAsync: mutatePaymentOrder } = useCreatePaymentOrder();

  const stopTimer = () => {
    setIsActive(false);
  };

  let intervalId: any = null;

  useEffect(() => {
    const makeApiCall = async () => {
      if (transactionId) {
        if (isActive && seconds !== 0) await refetchTransaction();
      }
    };

    intervalId = setInterval(makeApiCall, 1500);

    return () => clearInterval(intervalId);
  }, [transactionId]);

  useEffect(() => {
    (async () => {
      if (transaction) {
        if (transaction.status === "3") {
          setIsActive(false);

          order.reset();

          setModalMessage("Compra Finalizada");

          setTimeout(() => {
            navigation.navigateTo("/login-user");

            setModalMessage("");
          }, 3000);
        }
        if (transaction.status !== "1" && transaction.status !== "3") {
          setIsActive(false);

          setShowBtn(true);
          setShowRetryBtn(true);
          setModalMessage("Falha ao finalizar sua compra. tente novamente");
        }
      }
    })();
  }, [transaction]);

  useEffect(() => {
    (async () => {
      if (transaction) {
        if (transaction.status === "3") {
          clearInterval(intervalId);
          stopTimer();
        }
        if (transaction.status !== "3" && seconds === 0) {
          clearInterval(intervalId);
          stopTimer();
        }
      }
    })();
  }, [transaction, seconds]);

  useEffect(() => {
    (async () => {
      try {
        const Payload: CreatePaymentOrder = {
          amount: order.totalAmount,
          entityId: userManager.entity,
          clientName: order.clientName ?? "Totem",
          terminalId: getTerminalId() ?? "",
          reference: "MenuPass",
          application: "MenuPass",
          paymentMethod: Number(paymentMethod),
          isSelfCheckoutTotem: true,
          items: order.items.map((item) => {
            const {
              id, name, amount, price, totalAmount
            } = item;

            return {
              id,
              name,
              quantity: amount,
              price,
              totalAmount,
            };
          }),
        };
        const response: CreatePaymentOrderResponse = await mutatePaymentOrder(Payload);
        if (response?.id) {
          setTransactionId(response?.id);
          setIsActive(true);
          setSeconds(300);
          return;
        }
        console.log("error", 1);
        setModalMessage("Falha ao finalizar sua compra. tente novamente");
        order.reset();
        setShowBtn(true);
      } catch (error) {
        console.log("error to create payment ", 2);
      }
    })();
  }, []);

  const handleClose = () => {
    order.reset();
    navigation.navigateTo("/login-user");
    setModalMessage("");
  };

  const handleBack = () => {
    navigation.navigateTo("/confirm-manual-purchase", {
      state: {
        type: "visitor",
      },
    });
  };

  if (paymentMethod === PaymentMethod.CREDIT_CARD || paymentMethod === PaymentMethod.DEBIT_CARD) {
    return (
      <div className={Styles.container}>
        <div className={Styles.containerRelative}>
          <div className={Styles.formView}>
            <Typography className={Styles.title}>
              {`Pagamento ${getPaymentMethodName(paymentMethod)} - ${order.clientName}`}
            </Typography>

            <div className={Styles.PixContainer}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <div>
                  <Typography className={Styles.subTitle}>
                    Aguardando Pagamento
                  </Typography>
                  <LinearProgress color="primary" />
                </div>
              )}
            </div>

            <Typography className={Styles.subTitle}>
              Pague na máquina de cartão
            </Typography>

            <div className={Styles.actions}>
              <Typography className={Styles.subTitle}>
                Para cancelar a compra, por favor realize o processo na máquina
              </Typography>
            </div>

            {/* <div className={Styles.actions}>
              <Button
                color="secondary"
                size="large"
                fullWidth
                onClick={() => handleBack()}
              >
                Voltar
              </Button>
            </div> */}
          </div>

          <LoadingModal
            open={!!modalMessage}
            message={modalMessage}
            showButton={showBtn}
            showRetryButton={showRetryBtn}
            onClick={() => handleClose()}
            onClickRetry={() => handleBack()}
          />
        </div>
      </div>
    );
  }
  return <div />;
};
