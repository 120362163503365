import { Modal, TextField, Typography } from "@material-ui/core";

import { Close, RemoveOutlined, AddOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";

import { useStore } from "../../../store";
import { Item, Product } from "../../../types";

import { formatCurrency } from "../../../utils/format";
import { Button } from "../../index";
import useStyles from "./styles";
import { useEntity } from "../../../hooks/useEntity";

export interface ProductModalProps {
  open: boolean;
  onClose: () => void;
  product: Product | Item;
  isEditing?: boolean;
}

export const ProductModal: React.FC<ProductModalProps> = ({
  open,
  onClose,
  product,
  isEditing,
}) => {
  const Styles = useStyles({ picture: product.picture });

  const {
    order,
    userTotem,
  } = useStore();

  const { showObservations } = useEntity();

  const [amount, setAmount] = useState<number>(1);
  const [value, setValue] = useState<string>('');
  const total = amount * (product.price / 100);

  const isAvailable = (amount + 1) * (product.price / 100) <= ((userTotem.wallet.periodLimit - userTotem.wallet.spent)) || !userTotem.id;

  const changeAmount = (number: number) => {
    if (number >= 1 && number <= product.quantity) {
      setAmount(number);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 140) {
      setValue(inputValue);
    }
  };

  const addItemToCart = useCallback(() => {
    order.addItem({
      ...product,
      amount,
      observation: value,
    }, !userTotem.id);

    onClose();
  }, [amount, value]);

  const editQuantityItemToCart = useCallback(() => {
    order.editQuantity(product.id, amount);

    onClose();
  }, [amount]);

  const thereIsChange = useCallback(
    (): boolean => product.amount === amount,
    [amount]
  );

  const disableAddToCart = useCallback(
    (): boolean => {
      if (userTotem.virtualCard) {
        if (isEditing) {
          const newTotal = total - (product.amount / 100) * amount;
          return userTotem.wallet && (newTotal > (userTotem.virtualCard.balance / 100));
        }

        return userTotem.wallet && (total + (order.totalAmount / 100) > (userTotem.wallet.periodLimit - userTotem.wallet.spent) || total > (userTotem.virtualCard.balance / 100) || (order.totalAmount / 100) + total > (userTotem.virtualCard.balance / 100));
      }

      return false;
    },
    [total, amount, userTotem, order, isEditing]
  );

  useEffect(() => {
    if (product.amount) {
      setAmount(product.amount);
    }
  }, []);

  return (
    <Modal className={Styles.modal} open={open} onClose={onClose}>
      <div className={Styles.modalView}>
        <div className={Styles.close} onClick={onClose}>
          <Close />
        </div>

        <div className={Styles.info}>
          <div className={Styles.image} />

          <Typography component="span">{product.name}</Typography>
        </div>

        {showObservations && (
          <div className={Styles.observation}>
            <TextField
              id="standard-basic"
              label="Digite uma observação"
              variant="standard"
              inputProps={{ maxLength: 140 }}
              value={value}
              onChange={handleChange}
            />
          </div>
        )}

        <div className={Styles.orderView}>
          <div className={Styles.amount}>
            <div
              className={Styles.icon}
              onClick={() => changeAmount(amount - 1)}
            >
              <RemoveOutlined />
            </div>

            <Typography component="span">{amount}</Typography>

            <div
              className={`${Styles.icon}${
                !isAvailable ? ` ${Styles.iconDisabled}` : ""
              }`}
              onClick={() => isAvailable && changeAmount(amount + 1)}
            >
              <AddOutlined />
            </div>
          </div>

          <div className={Styles.total}>
            <Typography component="span" align="left">
              Total
            </Typography>

            <Typography component="span" align="right">
              {formatCurrency(total)}
            </Typography>
          </div>

          <Button
            className={Styles.addToCart}
            onClick={() =>
              isEditing ? editQuantityItemToCart() : addItemToCart()}
            disabled={thereIsChange() || disableAddToCart()}
          >
            {!isEditing ? "Adicionar ao Carrinho" : "Salvar Alterações"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
